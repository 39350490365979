<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-table
      :headers="headers"
      :items="dataTable"
      :search="search"
      :loading="loadingTableQuotations"
      :options.sync="options"
      hide-default-footer
      :disable-pagination="true"
      :disable-sort="true"
      :key="`list-quote-${tenantId}`"
      loading-text="Cargando registros..."
      item-key="quoteId"
      class="elevation-1"
      dense
      small
    >
      <template v-slot:top>
        <ExpandableFilters
          title=""
          :filters="filters"
          :titleButton="
            puedoCrearCotizacionVentaPos ? 'Nueva cotización' : null
          "
          iconToolbar=""
          classButton="py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0"
          :actionButton="
            () => {
              dialogSearchClient = true;
            }
          "
          @datafilters="getQuotations(true)"
        />
        <v-col cols="12">
          <v-row class="py-0">
            <v-col cols="12" class="py-0">
              <v-radio-group v-model="filterState" class="my-0" row>
                <v-radio
                  :label="`Creadas (${cotCreadas})`"
                  color="primary"
                  value="creadas"
                  @click="getQuotations"
                ></v-radio>
                <v-radio
                  :label="`Por aprobar (${quotesToApprove})`"
                  color="primary"
                  value="to_be_approved"
                  @click="getQuotations"
                ></v-radio>
                <v-radio
                  :label="`Aprobadas (${quotesApproved})`"
                  color="primary"
                  value="aproved"
                  @click="getQuotations"
                ></v-radio>
                <v-radio
                  :label="`Por confirmar (${cotPorConfirmar})`"
                  color="primary"
                  value="porconfirmar"
                  @click="getQuotations"
                ></v-radio>
                <v-radio
                  :label="`Confirmadas (${cotConfirmadas})`"
                  color="primary"
                  value="confirmed"
                  @click="getQuotations"
                ></v-radio>
                <v-radio
                  :label="`Despachadas (${cotImpresas})`"
                  color="primary"
                  value="dispatched"
                  @click="getQuotations"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot:item.quote_id="{ item }">
        <v-btn text link :to="`/cotizacion/${item.quote_id}`">
          {{ item.quote_id }}
        </v-btn>
      </template>
      <template v-slot:item.total="{ item }">
        <span>$ {{ item.total }}</span>
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="totalItems"
          @input="handlePageChange"
          total-visible="15"
        ></v-pagination>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDetail"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row>
            <v-col cols="12" md="8" sm="12" class="caption text-md-h6">
              {{ dataRequestQuotation.cotizacion.partyNombres }}
              {{ dataRequestQuotation.cotizacion.partyCedula }} -
              {{ dataRequestQuotation.cotizacion.quoteId }} -
              {{ statusQuotation }} - {{ dateQuotation }}
            </v-col>
            <v-col
              class="d-none d-md-block text-right caption text-md-h6"
              md="4"
            >
              <v-icon>mdi-monitor</v-icon>
              <span style="position:relative;top:2px;margin-right:25px"
                >POS 1</span
              >
              <v-icon>mdi-account-circle</v-icon>
              <span style="position:relative;top:2px">{{
                this.user.user_ligin_id.toUpperCase()
              }}</span>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSearchClient" persistent max-width="700px">
      <v-card>
        <v-form ref="form_search_client" class="mt-2">
          <v-row class="mx-1">
            <v-col cols="12" class="text-center pb-0 mt-1 col-md-4">
              <v-autocomplete
                v-model="location"
                :items="ubications"
                :rules="ruleRequired"
                item-text="geo_name"
                item-value="geo_id"
                class="ml-5 mr-5 mr-md-0"
                label="Seleccione una dirección"
                return-object
              />
            </v-col>
            <v-col cols="12" class="text-center py-0 py-md-3 col-md-8 ">
              <v-text-field
                :rules="ruleRequired"
                append-icon="mdi-magnify"
                @click:append="searchClient"
                @keyup.enter="searchClient"
                v-model="client"
                label="Buscar cliente por nombre, identificación, o ID"
                :loading="searchClientLoading"
                class="mx-5"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-col cols="12" class="text-center pt-0" v-if="!newClient">
          <v-data-table
            v-model="clientSelected"
            :headers="headersClient"
            :items="dataTableClient"
            :items-per-page="15"
            :single-select="true"
            :loading="loadingTableClient"
            item-key="party_id"
            loading-text="Buscando cliente..."
            class="elevation-1"
            show-select
            dense
          >
            <template v-slot:footer>
              <v-col v-if="openExistsQuoation">
                <v-row>
                  <v-col cols="12">
                    <v-alert dense type="info" icon="mdi-check">
                      COTIZACIONES ACTIVAS, SELECCIONE UNA
                    </v-alert>
                  </v-col>
                  <v-col
                    md="6"
                    class="pt-0"
                    v-for="(q, x) in existQuotes"
                    :key="x"
                  >
                    <v-btn
                      color="info"
                      :href="`/cotizacion/${q.quote_id}`"
                      small
                    >
                      <v-icon>mdi-skip-next</v-icon> Abrir cotización
                      {{ q.quote_id }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  @click="createQuotation"
                  v-if="continueQuoation"
                  class="mx-1"
                  small
                >
                  <v-icon>mdi-skip-next</v-icon> Nueva cotización
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="closeDialogClient"
                  class="mx-1"
                  small
                >
                  <v-icon>mdi-cancel</v-icon> Cancelar
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else cols="12" class="text-center pt-0">
          <v-form ref="form_client">
            <v-row>
              <v-col cols="12">
                <v-alert dense type="warning" icon="mdi-account-remove">
                  CLIENTE NO ENCONTRADO, VERIFIQUE LOS DATOS PARA CREARLO
                </v-alert>
              </v-col>

              <v-col cols="12" md="12" class="py-0">
                <v-select
                  v-model="dataNewClient.party_type_id"
                  :items="partyTypes"
                  :rules="ruleRequired"
                  item-text="description"
                  item-value="party_type_id"
                  class="ml-5 mr-5 mr-md-0"
                  label="Tipo de cliente"
                  @change="changePartyType"
                />
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-autocomplete
                  v-model="dataNewClient.identification_type"
                  :items="tipoIdentificaciones"
                  :rules="ruleRequired"
                  item-text="description"
                  item-value="party_identification_type_id"
                  class="ml-5 mr-5 mr-md-0"
                  label="Tipo de identificación"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :rules="ruleRequired"
                  v-model="dataNewClient.identification"
                  label="Identificación"
                  class="mx-5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :rules="ruleRequired"
                  v-model="dataNewClient.first_name"
                  label="Nombres"
                  v-if="dataNewClient.party_type_id == 'PERSON'"
                  class="mx-5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :rules="ruleRequired"
                  v-model="dataNewClient.last_name"
                  label="Apellidos"
                  class="mx-5"
                  v-if="dataNewClient.party_type_id == 'PERSON'"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :rules="ruleRequired"
                  v-model="dataNewClient.group_name"
                  label="Razón social"
                  class="mx-5"
                  v-if="dataNewClient.party_type_id == 'PARTY_GROUP'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :rules="ruleRequired"
                  v-model="dataNewClient.group_name_local"
                  label="Nombre comercial"
                  class="mx-5"
                  v-if="dataNewClient.party_type_id == 'PARTY_GROUP'"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="text-center my-3">
                <v-btn
                  color="primary"
                  class="mx-1"
                  small
                  @click="storeNewClient"
                >
                  <v-icon>mdi-floppy</v-icon> Guardar
                </v-btn>
                <v-btn
                  color="secondary"
                  class="mx-1"
                  small
                  @click="closeDialogClient"
                >
                  <v-icon>mdi-cancel</v-icon> Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import DetailQuotationComponent from "../pos/DetailQuotationComponent";
import ExpandableFilters from "../general/ExpandableFilters";
import router from "../../router";
import moment from "moment";

export default {
  name: "PosComponent",
  components: {
    DetailQuotationComponent,
    ExpandableFilters,
  },
  data: () => ({
    headers: [
      { text: "Número", align: "start", value: "quote_id", width: 80 },
      { text: "Fecha", align: "start", value: "issueDate", width: 120 },
      { text: "Cliente", align: "start", value: "cliente", width: 220 },
      { text: "Estado", align: "start", value: "estado_cot", width: 80 },
      { text: "Creado por", align: "start", value: "creado_por", width: 60 },
      { text: "Envío", align: "start", value: "envio", width: 50 },
      {
        text: "Modificado fecha",
        align: "start",
        value: "modificado_fecha",
        width: 50,
      },
      { text: "Total", value: "total", width: 80, align: "end" },
      { text: "Comentarios", align: "start", value: "descripcion", width: 180 },
    ],
    headersClient: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombre", align: "start", value: "first_name" },
      { text: "Apellido", align: "start", value: "last_name" },
      { text: "Idetnificación", align: "start", value: "id_value" },
    ],
    dataTableClient: [],
    clientSelected: [],
    dataTable: [],
    //identificationTypes:[],
    dataRequestQuotation: {
      cotizacion: {},
    },
    options: {},
    dialogParams: {
      title: "",
      detailQuotation: true,
      actionQuotation: 1,
    },
    defaultDialogParams: {
      title: "",
      detailQuotation: false,
      actionQuotation: 1,
    },
    filters: [
      {
        cols: 4,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Número",
        type: "input_text",
      },
      {
        cols: 8,
        class: "py-0 py-md-2 col-md-4 pr-0 pl-0 pr-sm-2 mb-1",
        v_model: [],
        label: "Fecha",
        type: "input_date_time",
        clearable: true,
      },
      {
        cols: 4,
        class: "py-0 pr-0 pr-md-2 py-md-2 col-md-6 pl-0 pl-sm-2",
        v_model: "",
        label: "Cliente",
        type: "input_text",
      },
      {
        cols: 3,
        class: "py-0 pr-0 pr-md-2 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Usuario",
        type: "input_text",
      },
      {
        cols: 4,
        class: "py-0 pr-0 pr-md-2 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Estado",
        type: "input_select",
        text: "estado",
        value: "estadoId",
        clearable: true,
        items: [
          { estadoId: "Creada", estado: "Creada" },
          { estadoId: "Pedido reservado", estado: "Por confirmar" },
          { estadoId: "confirmado", estado: "Confirmada" },
          { estadoId: "impreso", estado: "Impreso" },
        ],
      },
    ],
    partyTypes: [
      {
        party_type_id: "PERSON",
        description: "Persona",
      },
      {
        party_type_id: "PARTY_GROUP",
        description: "Empresa",
      },
    ],
    continueQuoation: false,
    loadingTableQuotations: false,
    loadingTableClient: false,
    dialogSearchClient: false,
    dialogDetail: false,
    openExistsQuoation: false,
    location: "",
    search: "",
    client: "",
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    filterState: "all",
    newClient: false,
    searchClientLoading: false,
    existQuotes: null,

    dataNewClient: {
      party_type_id: "",
      identification_type: null,
      identification: null,
      first_name: null,
      last_name: null,
      group_name: null,
      group_name_local: null,
      party_type_id: null,
    },
    mostrarFirstName: false,
    mostrarLastName: false,
    mostrarGroupName: false,
    mostrarGroupNameLocal: false,

    defaultDataNewClient: {
      party_type_id: "PERSON",
      identification_type: null,
      identification: null,
      first_name: null,
      last_name: null,
      group_name: null,
      group_name_local: null,
    },

    tipoIdentificaciones: [
      {
        party_identification_type_id: "CEDULA",
        description: "Cédula",
      },
      {
        party_identification_type_id: "PASAPORTE",
        description: "Pasaporte",
      },
      {
        party_identification_type_id: "RUC",
        description: "RUC",
      },
    ],

    resume: [],
    ruleRequired: [(v) => !!v || "El campo es requerido"],
  }),
  watch: {
    dialogDetail: function(val) {
      !!val || this.closeDetail();
    },

    filterState: function(val) {
      this.continueQuoation = val.length == 0;
    },

    options: {
      handler() {
        this.getQuotations();
      },
      deep: true,
    },

    clientSelected: function(val) {
      this.continueQuoation = val.length > 0;
      if (typeof this.clientSelected[0] != "undefined") {
        this.openExistsQuoation = !!this.clientSelected[0].quotes.length;
        this.existQuotes = this.clientSelected[0].quotes;
      } else {
        this.openExistsQuoation = false;
        this.existQuotes = [];
      }
    },

    location(val) {
      this.setUbication(val);
      //this.dataShippmentMethods()
    },
  },
  computed: {
    ...mapState("master", ["user", "tenantId", "overlay", "posTerminalId"]),
    ...mapState("pos", ["wayToPays", "ubications", "menuPos"]),
    ...mapGetters("access", ["puedoCrearCotizacionVentaPos"]),
    cotCreadas() {
      let creadas = this.resume.find((e) => e.estado_cot === "QUO_CREATED");
      return typeof creadas != "undefined" ? creadas.total : 0;
    },

    quotesApproved() {
      let sum = 0;
      for (var i = 0; i < this.resume.length; i++) {
        if (
          this.resume[i].estado_cot == "QUO_APPROVED" ||
          this.resume[i].estado_cot == "QUO_SEG_CLIENTE"
        ) {
          sum += this.resume[i].total;
        }
      }

      return sum;
    },

    quotesToApprove() {
      let toApproved = this.resume.find(
        (e) => e.estado_cot === "QUO_SOL_AP_SUP"
      );
      return typeof toApproved != "undefined" ? toApproved.total : 0;
    },

    cotPorConfirmar() {
      let porConfirmar = this.resume.find(
        (e) => e.estado_cot === "ORDER_CREATED"
      );
      return typeof porConfirmar != "undefined" ? porConfirmar.total : 0;
    },

    cotConfirmadas() {
      let confirmadas = this.resume.find((e) => e.estado_cot === "Confirmada");
      return typeof confirmadas != "undefined" ? confirmadas.total : 0;
    },
    cotImpresas() {
      let impresas = this.resume.find((e) => e.estado_cot === "Impreso");
      return typeof impresas != "undefined" ? impresas.total : 0;
    },
    dateQuotation() {
      if (typeof this.dataRequestQuotation.cotizacion != "undefined")
        return moment(this.dataRequestQuotation.cotizacion.issueDate).format(
          "DD/MM/YYYY"
        );
    },

    statusQuotation() {
      if (typeof this.dataRequestQuotation.cotizacion != "undefined")
        return this.dataRequestQuotation.cotizacion.estado;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setDrawer",
    ]),
    ...mapMutations("pos", ["setUbication", "setQuoteId"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("pos", ["dataShippmentMethods", "dataPrimaryPos"]),

    handlePageChange(value) {
      this.currentPage = value;
      this.getQuotations();
    },

    getQuotations() {
      if (this.currentPage != 1 && this.search != "") {
        this.currentPage = 1;
        return false;
      }

      this.dataTable = [];
      this.loadingTableQuotations = true;

      this.setUrl("lista-cotizaciones");
      this.requestApi({
        method: "GET",
        data: {
          state: this.filterState,
          search: this.search,
          page_size: this.itemsPerPage,
          page: this.currentPage,
          quote_id: this.filters[0].v_model,
          quote_date: this.filters[1].v_model,
          quote_client: this.filters[2].v_model,
          quote_usuario: this.filters[3].v_model,
          quote_estado: this.filters[4].v_model,
        },
      }).then((res) => {
        console.log(res);
        let data = res.data._embedded.lista_cotizaciones[0];
        //this.identificationTypes = res.data._embedded.lista_cotizaciones[0].tipoIdentificaciones
        this.dataTable = data.lista;
        this.totalItems = data.totalFilas;
        this.loadingTableQuotations = false;
        this.resume = data.resumen;
      });
    },

    dialogClient() {
      this.dialogSearchClient = true;
    },

    closeDialogClient() {
      this.dialogSearchClient = false;
      this.clientSelected = [];
      this.dataTableClient = [];
      this.location = "";
      this.client = "";
      this.newClient = false;

      Object.assign(this.dataNewClient, {
        party_type_id: "PERSON",
        identification_type: null,
        identification: null,
        first_name: null,
        last_name: null,
        group_name: null,
        group_name_local: null,
      });
    },

    /*openDialogDetail(title,action,item){   
            
            this.setOverlay(true)
            this.setUrl('cotizacion')

            this.requestApi({
                method : 'GET',
                data:{
                    quoteId: item.quote_id,
                }
            }).then(res=>{
                this.dataRequestQuotation = res.data
                
                this.dialogParams.title= title+' #'+item.quote_id
                this.dialogParams.actionQuotation = action
                this.setQuoteId(res.data.cotizacion.quoteId) // Setea el quoteId de turno
                this.dialogParams.detailQuotation = true //Agrega el contenido al dialog
                this.dialogDetail = true    // Abre el dialog
                this.setOverlay(false)  // Cierra el overlay
                this.dialogSearchClient=false
            }).catch(()=>{
                this.setOverlay(false)
            })

        },*/

    closeDetail() {
      this.dialogDetail = false;
      this.setOverlay(false);
      Object.assign(this.dialogParams, this.defaultDialogParams);
    },

    searchClient() {
      if (!this.$refs.form_search_client.validate()) return false;

      this.searchClientLoading = true;
      this.loadingTableClient = true;
      this.setUrl("buscar-persona");
      this.requestApi({
        method: "POST",
        data: {
          cliente: this.client,
        },
      })
        .then((res) => {
          console.log(res);
          //if(res.data[0].encontrado == 'si'){
          if (res.data.detail.encontrado == "si") {
            console.log(res.data.detail);
            this.dataTableClient = res.data.detail.clientes;
            this.newClient = false;
            console.log(this.dataTableClient);
          } else {
            this.continueQuoation = false;
            this.dataTableClient = [];
            this.newClient = true;

            if (res.data.detail.clientes != "") {
              const {
                nombres,
                apellidos,
                indetificacion,
              } = res.data.detail.clientes;

              this.dataNewClient = {
                first_name: nombres,
                last_name: apellidos,
                identification: indetificacion,
              };
              if (indetificacion.length == 10) {
                this.dataNewClient.party_type_id = "PERSON";
                this.dataNewClient.identification_type = "CEDULA";
              } else if (indetificacion.length == 13) {
                this.dataNewClient.identification_type = "RUC";
              }
            } else {
              this.dataNewClient = this.defaultDataNewClient;
            }
          }

          this.searchClientLoading = false;
          this.loadingTableClient = false;
        })
        .catch(() => {
          this.loadingTableClient = false;
          this.searchClientLoading = false;
        });
    },

    createQuotation() {
      if (!this.$refs.form_search_client.validate()) return false;

      this.setOverlay(true);
      this.dialogSearchClient = false;
      this.setOverlay(true);
      const {
        party_id,
        direccionId,
        telefonoId,
        prescriptorId,
      } = this.clientSelected[0];
      console.log(this.clientSelected);
      this.setUrl("cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          posTerminalId: this.posTerminalId,
          clienteId: party_id,
          direccionId: direccionId,
          telefonoId: telefonoId,
          medicoId: prescriptorId,
          fpagoId: "CASH",
          envioDomId: "NO_SHIPPING",
          direccionEnvioId: direccionId,
          location: this.location,
          emailId: "",
        },
      }).then((res) => {
        console.log(res);
        this.getQuotations();
        this.dataRequestQuotation = res.data;
        this.dialogParams.actionQuotation = 2;
        this.dialogParams.detailQuotation = true; //Agrega el contenido al dialog
        this.dialogDetail = true; // Abre el dialog
        this.setQuoteId(res.data.cotizacion.quoteId);
        this.setOverlay(false);
        this.clientSelected = [];
        router.push(`/cotizacion/${res.data.cotizacion.quoteId}`);
      });
    },

    /*editTableproducts({edit, quote_id}) {
            edit && this.openDialogDetail('COTIZACIÓN',3,{quote_id: quote_id}) 
            this.getQuotations()
        },*/

    storeNewClient() {
      if (!this.$refs.form_client.validate()) return false;

      this.setOverlay(true);

      this.setUrl("datos-person");
      this.requestApi({
        method: "PUT",
        data: this.dataNewClient,
      })
        .then((res) => {
          console.log(res);
          this.alertNotification({
            param: { html: "Cliente creado" },
          });

          this.clientSelected = [
            {
              party_id: res.data.detail.partyId,
              telefonoId: null,
              telefonoId: null,
              prescriptorId: null,
            },
          ];

          this.setOverlay(false);
          this.createQuotation();
          this.closeDialogClient();
        })
        .catch(() => {
          this.setOverlay(false);
        });
    },
    changePartyType() {
      if (this.dataNewClient.party_type_id == "PERSON") {
        /*   this.mostrarFirstName = true
                this.mostrarGroupName = false
                this.mostrarLastName = true
                this.mostrarGroupNameLocal=false*/
        this.tipoIdentificaciones = [
          {
            party_identification_type_id: "CEDULA",
            description: "Cédula",
          },
          {
            party_identification_type_id: "RUC",
            description: "RUC",
          },
          {
            party_identification_type_id: "PASAPORTE",
            description: "Pasaporte",
          },
        ];
      }

      if (this.dataNewClient.party_type_id == "PARTY_GROUP") {
        /*this.mostrarFirstName = false
                this.mostrarGroupName = true
                this.mostrarLastName = false
                this.mostrarGroupNameLocal=true*/
        this.tipoIdentificaciones = [
          {
            party_identification_type_id: "RUC",
            description: "RUC",
          },
        ];
      }
    },
  },
  created() {
    this.setDrawer(true);
    this.dataPrimaryPos();
    this.setTitleToolbar("COTIZACIONES DE VENTA");
  },
  mounted() {
    //this.setMenu(this.menuPos)
  },
  /*beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setUrl('caja-pos-user')
            vm.requestApi({
                method : 'PATCH',
                data :{ }
            }).then(res=>{
                res.data.detail == null && router.push('/transacciones_caja?pos=true')
            })
        }) 
    },*/
};
</script>

<style>
.v-pagination__navigation {
  margin: 0.3rem 0px !important;
}
</style>
